import React from "react"
import JotformEmbed from 'react-jotform-embed';

const SidebarForm = () => {
    return (
        <>
            <h2 className="text-center title text-3xl font-light pb-5 mb-7 mt-6">QUICK QUOTE</h2>
            <div className="border border-gray-100 md:pt-8 pt-0 pb-4 px-5">
                <iframe
                    src="https://form.jotformeu.com/211048518814454"
                    title="Free Quote Request Form"
                    className="w-full h-[310px] border-0"
                    loading="lazy"
                ></iframe>
                {/* <JotformEmbed src="https://form.jotformeu.com/211048518814454" /> */}
            </div>
        </>
    )
}
export default SidebarForm